import {mapActions, mapGetters, mapMutations} from "vuex";
import retailOrderAccordion from "../components/retail-order-accordion/index.vue";
//sections
export default {
  name: "orders-retail",
  components:{
    retailOrderAccordion
  },
  data(){
    return{

    }
  },
  created() {
    this.fetchOrders()
  },
  computed:{
    ...mapGetters({
      orders: 'profile/retailOrders'
    }),
  },

  methods:{
    ...mapActions({
      fetchOrders: 'profile/FETCH_RETAIL_ORDERS'
    }),
  }
}